import { useState } from "react";
import { csvParse } from "d3-dsv";

function emailToAccountFormat(email) {
    return email.replace("@", "-").replace(".", "-");
}

const parseData = async (filePath) => {
    try {
        const response = await fetch(filePath);
        if (!response.ok) {
            console.log("Missing file", filePath);
            return [];
        }

        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("text/csv")) {
            console.log("Invalid file format returned", filePath, response.headers.get("content-type"));
            return [];
        }

        const data = await response.text();
        const parsedData = csvParse(data);
        return parsedData;
    } catch (error) {
        console.error("Error fetching data", error);
        return [];
    }
};

const fetchMonthData = async (accountName, year, month) => {
    const fileName = `${emailToAccountFormat(
        accountName
    )}_${year}-${month}.tsv`;
    const filePath = `https://khp-data-lake.pages.dev/distrokid/${fileName}`;
    const data = await parseData(filePath);
    return data;
};

const fetchYearData = async (accountName, year) => {
    const fileName = `${emailToAccountFormat(accountName)}_${year}-*.tsv`;
    const filePath = `https://khp-data-lake.pages.dev/distrokid/${fileName}`;
    const data = await parseData(filePath);
    return data;
};

export function useMonthly() {
    const [data, setData] = useState(null);

    const getMonthData = async (accountName, year, month) => {
        const monthData = await fetchMonthData(accountName, year, month);
        setData(monthData);
    };

    return { data, getMonthData };
}

export function useYearly() {
    const [data, setData] = useState(null);

    const getYearData = async (accountName, year) => {
        const yearData = await fetchYearData(accountName, year);
        setData(yearData);
    };

    return { data, getYearData };
}
